<template>
  <v-btn
    :href="marianaTekLoginHref"
    :color="color"
    :plain="plain"
    :block="block"
    x-large
    :dark="(plain) ? false : true"
    depressed>
    {{buttonText}}
  </v-btn>
</template>

<script>
export default {
  name: 'LoginBtn',
  props: [
    'buttonText',
    'plain',
    'color',
    'block'
  ],
  data() {
    return {
    }
  },
  computed: {
    marianaTekLoginHref() {
      return `${this.$store.state.marianaTekUri}?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}&code_challenge=${this.challenge}${(this.prompt) ? '&prompt=true' : ''}`
    },
    clientId() {
      return this.$store.state.clientId
    },
    state() {
      return this.$store.state.state
    },
    challenge() {
      return this.$store.state.challenge
    },
    redirectUri() {
      return this.$store.state.redirectUri
    },
    prompt() {
      return this.$store.state.prompt
    },
    loginOnly() {
      return this.$store.state.login_only
    },
  },
  methods: {
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },
    generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    },
  },
  beforeMount() {
    this.challenge = this.generateId(40)
    this.$store.commit('setChallenge', this.challenge)
  },
}
</script>

<style scoped>
</style>