import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: {},
    user: {},
    challengeId: 2,
    challenge: null,
    challengeRecord: null,
    classCount: 0,
    baseEccoUrl: process.env.VUE_APP_BASE_ECCO_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    state: '39fh03fhj30gh0jfowej0fifjasodin0jhkg2',
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    marianaTekUri: process.env.VUE_APP_MARIANA_TEK_URI,
    marianaBaseUri: process.env.VUE_APP_BASE_MARIANA_URI,
    prompt: 0,
    signupError: null,
    locations: [],
    locationsLoading: false,
    ref: null,
  },

  mutations: {
    setSession: (state, val) => {
      state.session = val
    },
    setUser: (state, val) => {
      state.user = val
    },
    setChallenge: (state, val) => {
      state.challenge = val
    },
    setChallengeRecord: (state, val) => {
      state.challengeRecord = val
    },
    setTracking: (state, val) => {
      state.tracking = val
    },
    setClassCount: (state, val) => {
      state.classCount = val
    },
    setLocations: (state, val) => {
      state.locations = val
    },
    setLocationsLoading: (state, val) => {
      state.locationsLoading = val
    },
  },

  actions: {
    createAccount: ({ dispatch, commit, state }, data) => {
      axios.post(`${state.baseUrl}/me/account`, data).then((res) => {
        dispatch('signupAuthenticate', res.headers['x-ephemeral-token'])
        commit('setUser', res.data)
      }).catch((err) => {
        console.log(err.response)
        if (err.response.data?.email?.length > 0) {
          state.signupError = err.response.data.email[0]
        }
      })
    },

    authenticate: async ({ commit, dispatch, state }) => {
      axios.post(`${state.marianaBaseUri}/o/token/?client_id=${state.clientId}&code_verifier=${state.challenge}&code=${state.code}&grant_type=authorization_code&redirect_uri=${state.redirectUri}`)
      .then(async (data) => {
        commit('setSession', data.data)
        await dispatch('getProfile')
        router.push({ name: 'Home' })
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    },

    signupAuthenticate: ({ state }, data) => {
      window.location.href = `${state.marianaBaseUri}/o/authorize?response_type=code&client_id=${state.clientId}&redirect_uri=${state.redirectUri}&code_challenge=${state.challenge}&ephemeral_token=${data}`
    },

    getLocations: ({ commit, state }) => {
      commit('setLocationsLoading', true)
      axios.get(`${state.baseEccoUrl}/mariana/location`).then((res) => {
        commit('setLocations', res.data.data)
        commit('setLocationsLoading', false)
      }).catch((err) => {
        console.log(err.response)
        commit('setLocationsLoading', false)
      })
    },

    getProfile: async ({ commit, dispatch, state }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseUrl}/me/account`, {
          headers: {
            'Authorization': `Bearer ${state.session.access_token}`
          }
        }).then(async (res) => {
          commit('setUser', res.data)
          dispatch('checkForChallengeSignup')
          resolve('user set')
        }).catch((err) => {
          console.log(err.response)
          reject('error getting profile')
        })
      })
    },

    createChallengeSignup: async ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseEccoUrl}/challenge/signup`, data)
          .then(res => {
            commit('setChallengeRecord', res.data)
            resolve('challenge created')
          })
          .catch(err => {
            console.log(err)
            reject('error creating challenge')
          })
      })
    },

    checkForChallengeSignup: async ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseEccoUrl}/challenge/signup?customerId=${state.user.id}&challengeId=${state.challengeId}`)
          .then((res) => {
            if (res.data.total > 0) {
              commit('setChallengeRecord', res.data.data[0])
              router.push('/challenge')
              resolve(true)
            }
          })
          .catch(err => {
            console.log(err)
            reject('error retrieving challenge')
          })
      })
    },

    getTotalClasses: async ({ commit, state }, customerId) => {
      return new Promise((resolve, reject) => {
        axios.get(`${state.baseEccoUrl}/mariana/reservation?include=class_session&user_or_booked_on_behalf_of_user=${customerId}&class_session_min_date=2024-03-01&class_session_max_date=2024-03-31&status=check%20in&limit=100&reserved_for_guest=false`)
          .then(res => {
            commit('setClassCount', res.data.total)
            resolve('classes retrieved')
          })
          .catch(err => {
            console.log(err)
            reject('class retrieval failed')
          })
      })
    }
  },

  getters: {
    isAuthenticated(state) {
      return (state.session.access_token && state.session.access_token.length > 0)
    },
    hasSignedUp(state) {
      return (state.challengeRecord && state.challengeRecord.id)
    }
  },

  modules: {
  },
  plugins: [createPersistedState()],
})
