<template>
  <v-app id="app">
    <v-main>
      <v-container class="main-body pa-8">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body .theme--light.v-application {
  font-family: 'Montserrat', sans-serif;
  color: #422115;
  font-weight: 500;

  h1,h2,h3,h4,h5,h6 {
    font-weight: 800;
  }
}

#app {
  background-color: #ffffff;
}

.main-body {
  min-height: 100vh;
  max-width: 480px;
  background-color: #F8F4F0;
  box-shadow: 1px 0px 24px rgba(0,0,0,0.3);
}

.v-main__wrap {
  background-color: #ede4db;
}
</style>
