<template>
  <div class="login-signup">
    <v-row>
      <v-col>
        <img src="../assets/rayoga.svg">
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col class="text-center mb-9">
        <img src="../assets/page-header.svg">
      </v-col>
    </v-row>
    <div v-if="!showSignup" class="text-center">
      <login-btn color="#C1563E" block button-text="Login" />
      <v-btn v-if="!loginOnly" class="mt-5" plain @click="showSignup = true">Create an account</v-btn>
    </div>
    <div v-if="showSignup">
      <h1 class="mb-3 text-center">Create An Account</h1>
      <v-alert dismissible outlined type="error" v-model="signupError">{{signupError}}</v-alert>
      <ValidationObserver ref="obs" v-slot="{ invalid, validated, handleSubmit }">
        <v-form @submit.prevent="handleSubmit(createAccount)">
          <ValidationProvider name="First Name" rules="required" v-slot="{ errors, valid }">
            <v-text-field
              dense
              outlined
              label="First Name"
              v-model="firstName"
              :error-messages="errors"
              :success="valid"
              required
            />
          </ValidationProvider>
          <ValidationProvider name="Last Name" rules="required" v-slot="{ errors, valid }">
            <v-text-field
              dense
              outlined
              label="Last Name"
              v-model="lastName"
              :error-messages="errors"
              :success="valid"
              required
            />
          </ValidationProvider>
          <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid }">
            <v-text-field
              dense
              outlined
              label="Email"
              v-model="email"
              :error-messages="errors"
              :success="valid"
              required
            />
          </ValidationProvider>
          <ValidationProvider name="Phone Number" rules="required|min:10" v-slot="{ errors, valid }">
            <v-text-field
              dense
              outlined
              label="Phone Number"
              v-model="phoneNumber"
              :error-messages="errors"
              :success="valid"
              required
            />
          </ValidationProvider>
          <ValidationProvider name="Password" rules="required|confirmed:confirmPassword" v-slot="{ errors, valid }">
            <v-text-field
              dense
              outlined
              label="Password"
              v-model="password"
              :error-messages="errors"
              :success="valid"
              required
              type="password"
            />
          </ValidationProvider>
          <ValidationProvider name="Confirm Password" rules="required" vid="confirmPassword" v-slot="{ errors, valid }">
            <v-text-field
              dense
              outlined
              label="Confirm Password"
              v-model="confirmPassword"
              :error-messages="errors"
              :success="valid"
              required
              type="password"
            />
          </ValidationProvider>
          <ValidationProvider name="Preferred Studio" rules="required" v-slot="{ errors, valid }">
            <v-select
              dense
              outlined
              label="Preferred Studio"
              hide-details
              v-model="preferredStudio"
              :items="locations"
              :loading="locationsLoading"
              :disabled="locationsLoading"
              item-text="attributes.name"
              item-value="id"
              :error-messages="errors"
              :success="valid"
              required />
          </ValidationProvider>
          <v-checkbox
            class="mt-5"
            dense
            label="I would like to receive marketing emails and texts from Ra Yoga"
            v-model="marketingAllowed" />
          <v-row class="align-center">
            <v-col cols="1">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-checkbox
                  dense
                  required
                  v-model="signedWaiver"
                  :error-messages="errors" />
              </ValidationProvider>
            </v-col>
            <v-col class="text-left">
              <label class="v-label theme--light">I have read and agree to the <a target="_blank" href='https://rayoga.com/liability-waiver/'>Ra Yoga Waiver</a></label>
            </v-col>
          </v-row>
          <v-btn
            class="mt-5 mb-3"
            type="submit"
            color="primary"
            block
            depressed
            :disabled="signedWaiver && invalid || !validated"
            >
            Create Account
          </v-btn>
        </v-form>
      </ValidationObserver>
      <v-col class="text-center">
        <login-btn :plain="true" button-text="Already have an account?" />
      </v-col>
    </div>
  </div>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate/dist/vee-validate.full'

import axios from 'axios'
import { mapGetters } from 'vuex'
import loginBtn from '@/components/LoginBtn.vue'

export default {
  name: 'LoginSignup',

  components: {
    loginBtn,
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      password: null,
      confirmPassword: null,
      marketingAllowed: true,
      signedWaiver: false,
      preferredStudio: null,
      showSignup: false,
    }
  },

  computed: {
    ...mapGetters(['locationsLoading']),
    locations() {
      return this.$store.state.locations
    },
    requireSignup() {
      return this.$store.state.require_signup
    },
    loginOnly() {
      return this.$store.state.login_only
    },
    signupError() {
      return this.$store.state.signupError
    },
    bannerText() {
      return this.$store.state.banner_text
    },
    desktopImageUrl() {
      return this.$store.state.desktop_image_url
    },
    mobileImageUrl() {
      return this.$store.state.mobile_image_url
    }
  },

  methods: {
    createAccount() {
      //this.recaptcha();
      this.$store.dispatch('createAccount', {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone_number: this.phoneNumber,
        password: this.password,
        is_marketing_allowed: this.marketingAllowed,
        is_opted_in_to_sms: this.marketingAllowed,
        is_waiver_signed: this.signedWaiver,
        home_location: {
          id: this.preferredStudio,
        },
      })
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('signup')

      // Do stuff with the received token.
      console.log(token);
      const test = await axios.post(`https://ecco-api.rayoga.com/recaptcha`, {
        token,
      })
      console.log(test)
    }
  },
  
  mounted() {
    if (this.requireSignup) {
      this.showSignup = true
    }

    this.$store.dispatch('getLocations')
  }
}
</script>

<style lang="scss">

</style>