import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {

  if (store.state.session.access_token === null) {
    if (to.path === '/login' || to.path === '/oauth/callback' || to.path === '/') {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
